body {
  // padding-top: 70px;
  // background: #FFF url(/assets/img/bg.png) repeat 0 0;

  .wrapper {
    // background-color: white;
  }
}

h3.acharya {
  text-align: center;
}

div.video-box {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9/16 * 100 = 56.25%) */
  height: 0;
  overflow: hidden;
  margin-bottom: 1em;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  flex-wrap: nowrap;
  margin-top: 20px;
  
  > .stretch {
    flex: 1;
    margin: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    flex-wrap: wrap;

    > .stretch {
      flex: none;
      width: 100%; /* Ensure each item takes full width in column layout */
    }
  }
}

.post-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  // margin-top: 20px;

  > .thumb {
    width: 80px;
    margin: 0;
  }
  
  > .content {
    flex: 1;
    margin: 0;
    color: #666;
    h3 {
      margin-bottom: 3px;
      line-height: 1.5em;
    }
    .post-summary {
      // font-size: $base-font-size * 1.1;
      color: $grey-color;
    }
  }
}

.post-img {
  margin-bottom: 20px;
  text-align: center;
  img {
    max-width: 100%;
  }
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.footer-name {
  color: #333;
  font-weight: 600;
}
